// Login Form section - having validation and form fields

import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { serverleturl } from "../../router/config";
import axios from "axios";


function Login(props) {
  useEffect(() => {
    if (localStorage.checkbox && localStorage.userName !== "") {
      setState({
        ...state,
        isChecked: true,
        userName: localStorage.userName,
        accountno: localStorage.accountno,
        password: localStorage.password,
      });
    }
  }, []);

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const [state, setState] = React.useState({
    accountno: "",
    userName: "",
    password: "",
    isChecked: false,
  });

  const onChangeValue = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onChangeCheckbox = (event) => {
    setState({ ...state, isChecked: event.target.checked });
  };

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              accountno: "",
              userName: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!state.accountno) {
                errors.accountno = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              if (!state.userName) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.userName = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }
              // else if (
              //   !/^[A-Z0-9._%+-]$/i.test(values.userName)
              // ) {
              //   errors.userName = intl.formatMessage({
              //     id: "AUTH.VALIDATION.INVALID_FIELD"
              //   });
              // }

              if (!state.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              if (state.isChecked === true && state.userName !== "") {
                //localStorage.accountno = state.accountno; //commented by karthik on 27/03/2025
                localStorage.userName = state.userName;
                localStorage.password = state.password;
                localStorage.checkbox = state.isChecked;
              }
              //below two lines added by karthika on 27/03/2025 to handle Balmer Lawrie UAE LLC
              if(state && state?.accountno)
                localStorage.setItem("accountno", state.accountno);    

              setTimeout(() => {
                const qs = require("querystring");
                const requestBody = {
                  account: state.accountno,
                  userName: state.userName,
                  password: state.password,
                  remember_me: "1",
                };
                const config = {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                };

                axios
                  .post(
                    serverleturl + "ReactLoginServlet",
                    qs.stringify(requestBody),
                    config
                  )
                  .then(function(response) {
                    const res = response.data;

                    if (res === 1) {
                      disableLoading();
                      props.login(res);
                      } else if (res === -1) {
                      disableLoading();
                      setSubmitting(false);
                      setStatus(
                        intl.formatMessage({
                          id: "AUTH.VALIDATION.GENERAL_ERROR",
                        })
                      );
                    } else if (res === -3) {
                      disableLoading();
                      setSubmitting(false);
                      setStatus(
                        intl.formatMessage({
                          id: "AUTH.VALIDATION.SUSBCRIPTION_EXPIRED",
                        })
                      );
                    } else if (res === -5) {
                      disableLoading();
                      setSubmitting(false);
                      setStatus(
                        intl.formatMessage({
                          id: "AUTH.VALIDATION.Suspended",
                        })
                      );
                    } else if (res === -6) {
                      disableLoading();
                      setSubmitting(false);
                      setStatus(
                        intl.formatMessage({
                          id: "AUTH.VALIDATION.ACCESS_RESTRICTED",
                        })
                      );
                    }else {
                      disableLoading();
                      setSubmitting(false);
                    }
                  })

                  .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN",
                      })
                    );
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                // autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Account No"
                    margin="normal"
                    className="kt-width-full"
                    name="accountno"
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={onChangeValue}
                    value={state.accountno}
                    helperText={touched.accountno && errors.accountno}
                    error={Boolean(touched.accountno && errors.accountno)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    label="User Name"
                    margin="normal"
                    id="email"
                    className="kt-width-full"
                    name="userName"
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={onChangeValue}
                    value={state.userName}
                    helperText={touched.userName && errors.userName}
                    error={Boolean(touched.userName && errors.userName)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={onChangeValue}
                    value={state.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>
                <div className="login_btn_section">
                  <div>
                    <FormControlLabel
                      value="end"
                      control={<Checkbox color="primary" />}
                      onChange={onChangeCheckbox}
                      id="rememberMe"
                      label="Remember me"
                      labelPlacement="end"
                    />
                  </div>
                  <div className="kt-login__actions">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
