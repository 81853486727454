
export const SERVICE_URL = "api/auth/login";

export const GOOGLEAPIKEY = "AIzaSyDkMms3OhCKPd6Vbl78-n4235HrFvWfaeA";


/**
 * Map Url
 */
//export const mapurl = "http://map3.informapcyprus.com:8080/geoserver/gwc/service/wms";

/**
 * 4G Map url
 */
 export const mapurl = "https://www.aegrl.com/geoserver/gwc/service/wms";



  /**
   * Layer Name
   */

  //export const MapLayer = "ITC:MAP"; 


  /**
   * 4G Layer Name
   */

 export const MapLayer = "JAN2019:JAN-2019"

// Local Environment

//export const serverurl = "http://localhost/RapidI-war/WRU/Gateway/";
//export const serverleturl ="http://localhost/RapidI-war/";
//export const imageServleturl = "http://localhost/RapidI-war/TraningVideosServlet?videoName="

//Localhost : 8080 Environment

//export const serverurl = "http://localhost:8080/RapidI-war/WRU/Gateway/";
//export const serverleturl ="http://localhost:8080/RapidI-war/";
//export const imageServleturl = "http://localhost:8080/RapidI-war/TraningVideosServlet?videoName="

// Development Environment - homepage : /IFR in package.json

// export const serverurl = "http://144.76.39.199:8080/RapidI-war/WRU/Gateway/";
// export const serverleturl = "http://144.76.39.199:8080/RapidI-war/";
// export const imageServleturl = "http://144.76.39.199:8080/RapidI-war/TraningVideosServlet?videoName="


// Development Environment1  - please change homepage as /IFR2 in package.json

//export const serverurl = "http://95.216.5.169/RapidI-war/WRU/Gateway/";
//export const serverleturl = "http://95.216.5.169/RapidI-war/";
//export const imageServleturl = "http://95.216.5.169/RapidI-war/TraningVideosServlet?videoName="


 
// Production Environment - GR1 - homepage : /IFR in package.json

//export const serverurl = "http://gr1.infofleet.com/RapidI-war/WRU/Gateway/";
//export const serverleturl = "http://gr1.infofleet.com/RapidI-war/";
//export const imageServleturl = "http://gr1.infofleet.com/RapidI-war/TraningVideosServlet?videoName="

// Production Environment - GR1 - homepage : /IFR in package.json (https)

//export const serverurl = "https://gr1.infofleet.com/RapidI-war/WRU/Gateway/";
//export const serverleturl = "https://gr1.infofleet.com/RapidI-war/";
//export const imageServleturl = "https://gr1.infofleet.com/RapidI-war/TraningVideosServlet?videoName="


//Production Environment - GR2  - homepage : /IFR2 in package.json

//export const serverurl = "http://gr2.infofleet.com/RapidI-war/WRU/Gateway/";
//export const serverleturl = "http://gr2.infofleet.com/RapidI-war/";
//export const imageServleturl = "http://gr2.infofleet.com/RapidI-war/TraningVideosServlet?videoName="


//Production Environment - GR4 - homepage : /IFR in package.json

//export const serverurl = "http://gr4.infofleet.com/RapidI-war/WRU/Gateway/";
//export const serverleturl = "http://gr4.infofleet.com/RapidI-war/";
//export const imageServleturl = "http://gr4.infofleet.com/RapidI-war/TraningVideosServlet?videoName="

//Production Environment - GR4 - homepage : /IFR in package.json (https)

// export const serverurl = "https://gr4.infofleet.com/RapidI-war/WRU/Gateway/";
// export const serverleturl = "https://gr4.infofleet.com/RapidI-war/";
// export const imageServleturl = "https://gr4.infofleet.com/RapidI-war/TraningVideosServlet?videoName="


/** Production Environment - GR5 - homepage : /IFR in package.json (https) **/
// added on 03/03/2025 by karthika

// export const serverurl = "https://gr5.infofleet.com/RapidI-war/WRU/Gateway/";
// export const serverleturl = "https://gr5.infofleet.com/RapidI-war/";
// export const imageServleturl = "https://gr5.infofleet.com/RapidI-war/TraningVideosServlet?videoName="

// Production Environment - IS2 - homepage : /IFR in package.json

//export const serverurl = "http://is2.infofleet.com:8080/RapidI-war/WRU/Gateway/";
//export const serverleturl = "http://is2.infofleet.com:8080/RapidI-war/";
//export const imageServleturl = "http://is2.infofleet.com:8080/RapidI-war/TraningVideosServlet?videoName="

// Production Environment - IS2 - homepage : /IFR in package.json (https)

//export const serverurl = "https://is2.infofleet.com/RapidI-war/WRU/Gateway/";
//export const serverleturl = "https://is2.infofleet.com/RapidI-war/";
//export const imageServleturl = "https://is2.infofleet.com/RapidI-war/TraningVideosServlet?videoName="


// Production Environment - 4G - homepage : / in package.json and change maplayer and mapurl for 4G

export const serverurl = "https://4g.infofleet.com/RapidI-war/WRU/Gateway/";
export const serverleturl = "https://4g.infofleet.com/RapidI-war/";
export const imageServleturl = "https://4g.infofleet.com/RapidI-war/TraningVideosServlet?videoName="










