export const reportlimit_days  = "You are limited to generate Maximum 31 days Report at a time!";
export const reportlimit_months  = "You are limited to generate Maximum 6 months Report at a time!";
export const days_count = 31; // 31 days
export const seven_days_count = 7 // 7 days
export const vehiclecount_maxlimit = 65;
export const reportlimit_seven_days_with_max65vehicles = "Maximum 7 days allowed for selecting more than 65 vehicles.";
export const quickOptionvalidationLastMonth = "Last month not allowed if more than 65 vehicles/drivers selected";
export const quickOptionvalidationThisMonth = "This month not allowed if more than 65 vehicles/drivers selected and no.of days > 7 days from start of this month";

export const monthsindays_count = 180; // 6 months
export  const TIMEOUT = 300000 * 9; // 5 min* 9 = 45 min time
export const DOWNLOAD_TIMEOUT = 300000 * 9; // 5 min* 9 = 45 min time
export const NoDataFound = "No data Found"

export const staticquickoptions = [
    { id: 1, name: "Today's" },
    { id: 2, name: "Yesterday's" },
    { id: 3, name: "Last 2 days" },
    { id: 4, name: "Last 3 days" },
    { id: 5, name: "Last 5 days" },
    { id: 6, name: "This week" },
    { id: 7, name: "Last week" },
    { id: 8, name: "This Month" },
    { id: 9, name: "Last Month" },
  ];
  

  export const StoppedTimedata =  [
    {id:1,value:"gt", label:"Greater than"},
    {id:2,value:"lt", label:"Less than"}
  ]


//mrp cardswipe mode
export const data_list_frequency = [
    {id: 1, displayVal: 'Four Times (Start, Reach, Return,End)', val: 1},
    {id: 2, displayVal: 'Two Times (Start & End)', val: 2}

];

//Pax or Job Frequency
export const Frequency = [
    {id: 1, displayVal: 'Once', val: 0},
    {id: 2, displayVal: 'Weekly', val: 1}
]

//WOX Trip data
export const WOXTripType = [
    {id: 1, name: 'Forward'},
    {id: 2, name: 'Return'},
    {id: 3, name: 'Both'}
]

//Wox Mode list
export const WoxModeList = [
    {id: 1, label: 'Entry', value: 'E'},
    {id: 2, label: 'Exit', value: 'X'}

];

//Weekdays Array
export const Weekdays = [
    {id: 1, val:"Sunday"},
    {id: 2, val:"Monday"},
    {id: 3, val:"Tuesday"},
    {id: 4, val:"Wednessday"},
    {id: 5, val:"Thursday"},
    {id: 6, val:"Friday"},
    {id: 7, val:"Saturday"}

]

//years and months for data download
export const years = [2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035]; // Add your desired years
export const months = [
  {id: 1, value: 'Jan' },
  {id: 2, value: 'Feb' },
  {id: 3, value: 'Mar' },
  {id: 4, value: 'Apr' },
  {id: 5, value: 'May' },
  {id: 6, value: 'Jun' },
  {id: 7, value: 'Jul' },
  {id: 8, value: 'Aug' },
  {id: 9, value: 'Sep' },
  {id: 10, value: 'Oct' },
  {id: 11, value: 'Nov' },
  {id: 12, value: 'Dec' }
];

//mrp functional tree Ids
export const mrp_parentId = 45; 
export const pax_assignmentId =  218;
export const visitpoisettingsId =   65
export const visitpoireportId =   67
export const ratsaccdnId =   217
export const customizedmrpId =   68
export const visitpoistatusId =   66

//fleet module functional tree ids
export const fleet_parentId = 43;
export const service_accordion_id = 59;
export const PaymentId = 60;
export const managesuppliersId = 61;
export const scheduledstatusId = 62;
export const fleethistoryId = 63;

//home module -> Geofence and POI functionatree ids
export const homeId = 42;
export const managemypoigfaccordId = 50;
export const POIId = 135;
export const Create_POIId = 137;
export const Edit_Delete_POIId = 138;
export const POI_Excel_UploadId = 287;
export const Add_sColumnsId = 288;
export const Assign_POI_toUserId = 290;
export const GeofenceId = 136;
export const Create_GeofenceId = 139;
export const Edit_Delete_GeofenceId = 140;

// panelsearch functional tree id
export const panelsearchId = 47

//home module -> Manage account functionatree ids
export const manageaccountaccordId = 48
export const ManageUserId = 1
export const EditUserAccountsId = 128
export const EditAssignFunctionalityId =  134
export const EditUserId =  132
export const EditAssignAssetsId =  133

export const CreateUserAccountId = 127
export const CreateUserId =  129
export const AssignAssetsId =  130
export const AssignFunctionalityId =  131

export const ManageFleetId = 2
export const CreateFleetId = 124
export const EditFleetId = 125
export const ViewFleetId = 126


export const SetPreferencesId = 3
export const TimezoneandUnit = 121 
export const AccountDetailsId = 118
export const AutoRefreshId = 120
export const LanguageId = 117
export const SaveMapviewId = 119

export const AccountId = 207
export const AddContactId = 208
export const EditContactId = 209
export const CompanyId = 210
export const OfficeHoursId = 212
export const HolidaysId = 213

//home module -> Manage settings functionatree ids
export const managesettingsId = 49

export const PrivateUsageId =  122

export const AssetId =   141
export const ViewAssetDetailsId =   147
export const EditAssetDetailsId =    148
export const AddPhotoId =   149
export const SuspendAssetId =   150
export const IssueCommandId =   151
export const SetorEditAlertId =   152


export const DriverId =  142
export const ViewDriverDetailsId =   154
export const EditDriverDetailsId =   155
export const AddDriverId =   156
export const AddDriverPhotoId =   157
export const AuthorizeDriverId =   158
export const ActivateorSuspendDriverId =   159
export const AssignDriverId =   251
export const DriverCumVehicleDetailsId =   312
export const UnAssignDriverId =   314
export const DriverAllowanceId =   320

export const FuelMileageId =  143
export const ViewMileageId =   160
export const AddorEditMileageId =   161

export const TechnicalHistoryId =  145
export const ViewEventSettingsId =   146
export const AddTerritoryId =  214
export const ViewTransactionLogsId =  215
export const ShareLocationLogId =  286
export const OfflineReportsId =  311

// Report module functionaltree ids
export const reportId =   44
export const instantactivityId =   53
export const instantusageId =   54
export const instanteventId =   55
export const instantioId =   56
export const factoryId =   57
export const customizedreportId =   58


export const ACCOUNT_NO='6005'; //added by karthika on 27/03/2025 ,'5585'- Testing dev accountno 